<template>
  <div>
    <div class="container-pefai-cards">
      <v-row class="padding-pefai">
        <v-col cols="12">
          <span class="text-title-card">
            Datos personales
          </span>
        </v-col>
      </v-row>
      <v-row class="padding-pefai">
        <v-col cols="3">
          <v-text-field
            label="Nombre"
            outlined
            v-model="data.nombre"
            color="#00a7e4"
            type="text"
            :rules="requiredRule"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Apellido paterno"
            outlined
            v-model="data.apellidoPaterno"
            color="#00a7e4"
            type="text"
            :rules="requiredRule"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Apellido materno"
            outlined
            v-model="data.apellidoMaterno"
            color="#00a7e4"
            type="text"
            :rules="requiredRule"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="data.fechaNacimiento"
            label="Fecha de Nacimiento"
            outlined
            color="#00a7e4"
            type="date"
            :rules="requiredRule"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="RFC"
            outlined
            v-model="data.rfc"
            color="#00a7e4"
            placeholder="XAXX010101000"
            :rules="rfcRules"
            type="text"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            label="Genero"
            placeholder="Requerido"
            :items="generoList"
            item-text="name"
            item-value="id"
            v-model="data.sexo"
            :rules="requiredRule"
            color="#00a7e4"
            type="text"
            outlined
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="3">
          <v-autocomplete
            label="Regimen"
            placeholder="Introduce el regimen"
            color="#00a7e4"
            outlined
            :items="regimentList"
            v-model="data.regimen"
            :rules="requiredRule"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Código Postal"
            color="#00a7e4"
            placeholder="#####"
            v-mask="'#####'"
            outlined
            v-model="data.codigoPostal"
            :rules="requiredRule"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Calle"
            color="#00a7e4"
            outlined
            v-model="data.calle"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Numero Exterior"
            color="#00a7e4"
            outlined
            v-model="data.num_exterior"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Numero interior"
            color="#00a7e4"
            outlined
            v-model="data.num_interior"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            label="Colonia"
            :items="colonias"
            item-text="nombreColonia"
            item-value="nombreColonia"
            color="#00a7e4"
            outlined
            v-model="data.colonia"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Ciudad"
            color="#00a7e4"
            outlined
            v-model="data.ciudad"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            label="Estado"
            item-text="name"
            item-value="id"
            :items="estados"
            color="#00a7e4"
            outlined
            v-model="data.estado"
            readonly
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import cocheLogo from "@/assets/cocheLogo.svg";
import { getAddressDataByCP } from "@/services/codigoPostal/cp.service.js";
var moment = require("moment-timezone");
import RfcFacil from "rfc-facil";

export default {
  components: {},

  data() {
    return {
      cocheLogo: cocheLogo,
      data: {
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        fechaNacimiento: "",
        rfc: "",
        codigoPostal: "",
        estado: "",
        ciudad: "",
        regimen: "Física",
        calle: "",
        num_exterior: "",
        num_interior: "",
        colonia: "",
        sexo:''
      },
      landings: [],
      ramos: [],
      requiredRule: [(v) => !!v || "El campo es requerido"],
      regimentList: ["Física", "Moral"],
      colonias: [],
      estados: [
        { id: 1, name: "Aguascalientes" },
        { id: 2, name: "Baja California" },
        { id: 3, name: "Baja California Sur" },
        { id: 4, name: "Campeche" },
        { id: 5, name: "Coahuila de Zaragoza" },
        { id: 6, name: "Colima" },
        { id: 7, name: "Chiapas" },
        { id: 8, name: "Chihuahua" },
        { id: 9, name: "Ciudad de México" },
        { id: 10, name: "Durango" },
        { id: 11, name: "Guanajuato" },
        { id: 12, name: "Guerrero" },
        { id: 13, name: "Hidalgo" },
        { id: 14, name: "Jalisco" },
        { id: 15, name: "Estado de México" },
        { id: 16, name: "Michoacán de Ocampo" },
        { id: 17, name: "Morelos" },
        { id: 18, name: "Nayarit" },
        { id: 19, name: "Nuevo León" },
        { id: 20, name: "Oaxaca" },
        { id: 21, name: "Puebla" },
        { id: 22, name: "Querétaro" },
        { id: 23, name: "Quintana Roo" },
        { id: 24, name: "San Luis Potosí" },
        { id: 25, name: "Sinaloa" },
        { id: 26, name: "Sonora" },
        { id: 27, name: "Tabasco" },
        { id: 28, name: "Tamaulipas" },
        { id: 29, name: "Tlaxcala" },
        { id: 30, name: "Veracruz de Ignacio de la Llave" },
        { id: 31, name: "Yucatán" },
        { id: 32, name: "Zacatecas" },
      ],
      rfcRules: [
        (v) => !!v || "RFC es necesario",
        (v) =>
          /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/.test(
            v
          ) || "RFC no es valido recordar escribir en mayusculas",
      ],
      generoList: [
        {
          id: "M",
          name: "Masculino",
        },
        {
          id: "F",
          name: "Femenino",
        },
      ],
    };
  },
  props: {
    getInfo: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    actualizarRfc() {
      if (
        !this.data.nombre ||
        !this.data.apellidoPaterno ||
        !this.data.apellidoMaterno ||
        !this.data.fechaNacimiento
      )
        return;
      var fechaformateada = moment(
        this.data.fechaNacimiento,
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY");

      const arregloNacimiento = fechaformateada
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.data.nombre,
        firstLastName: this.data.apellidoPaterno,
        secondLastName: this.data.apellidoMaterno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.data.rfc = rfc.substring(0, rfc.length);
    },
  },
  watch: {
    async "data.codigoPostal"(val) {
      if (val.length == 5) {
        var response = await getAddressDataByCP(val);
        this.colonias = response.listaColonias;
        this.data.estado = Number(response.nEstado);
        this.data.ciudad = response.nombreMunicipio;
        console.log(response);
      }
    },
    "data.fechaNacimiento"(newValue) {
      console.log({ newValue });
      this.actualizarRfc();
    },
    "data.nombre"() {
      this.actualizarRfc();
    },
    "data.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "data.apellidoMaterno"() {
      this.actualizarRfc();
    },
    getInfo(val) {
      if (val) {
        this.$emit("updatePersonalData", this.data);
      }
    },
  },
  computed: {},
  mounted() {},
};
</script>
